import React from 'react';

// === Components === //
import Error404Content from '@components/pages/Error404Content';

const NotFoundPage = () => {
  return <Error404Content />;
};

export default NotFoundPage;
