import React, { useEffect, useState } from 'react';

// === Components === //
import Page from '@components/layout/Page';

// === Content === //
import { error404Data } from '@graphql/Error404';
import chevronIcon from '@assets/svg/chevron_right.svg';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions, fluidRange, mixins, respondFrom } from '@styles';

// === Types === //
import { Error404Data } from '@content/types/error404';
import { PageType, Variant } from '@content/types/layout';

const HeroBanner = styled.div<BannerProps>`
  position: relative;
  height: 683px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 40px;
  background-image: url(${(props) => props.imageMobile});

  ${(props) =>
    respondFrom(
      breakpoints.md,
      css`
        height: 613px;
        background-image: url(${props.image});
      `
    )}
`;

const HeroBannerInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  padding: 0 50px;
`;

const HeroBannerContent = styled.div<ContentProps>`
  width: 100%;
  text-align: center;

  h3 {
    color: ${colors.white};
    ${fluidRange('fontSize', '35px', '45px')};
    text-shadow: 0 6px 15px rgba(0, 0, 0, 0.68);
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 40px;
  }

  h2 {
    display: inline-block;
    color: ${colors.white};
    ${fluidRange('fontSize', '100px', '220px')};
    font-weight: 700;
    margin-bottom: 40px;
    position: relative;

    ${(props) =>
      respondFrom(
        breakpoints.md,
        css`
          &::before {
            content: '';
            position: absolute;
            top: 50px;
            bottom: 0;
            left: -260px;
            width: 353px;
            height: 205px;
            margin: auto;
            background-image: url(${props.image});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        `
      )}
  }

  p {
    color: ${colors.white};
    font-size: 24px;
    text-shadow: 0 6px 15px rgba(0, 0, 0, 0.68);
    font-weight: 700;
    line-height: 45px;
  }

  a {
    position: relative;
    color: ${colors.white};
    text-decoration: none;
    text-shadow: none;
    display: inline-block;
    background-color: ${(props) =>
      props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 9px 18px 9px 40px;
    border-radius: 20px;
    line-height: 21px;
    ${mixins.transitionDefault};
    margin-top: 40px;

    ${respondFrom(
      breakpoints.md,
      css`
        margin-top: 20px;
      `
    )}

    &:hover {
      background-color: ${(props) =>
        props.variant === 'blue' ? colors.ui.patientDark : colors.ui.hcpDark};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      margin: auto;
      background-image: url(${chevronIcon});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
    }
  }
`;

interface BannerProps {
  image: string;
  imageMobile: string;
}

interface ContentProps {
  variant: Variant;
  image: string;
}

const Error404Content = () => {
  const [variant, setVariant] = useState<Variant>('blue');
  const [loading, setLoading] = useState<boolean>(true);
  const pageType: PageType = 'basic_page';
  const data: Error404Data = error404Data();

  const checkVariant = () => {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    if (url.search('/hcp') !== -1) {
      setVariant('red');
    }

    setLoading(false);
  };

  useEffect(() => {
    checkVariant();
  }, []);

  return (
    <>
      {!loading && (
        <Page variant={variant} pageType={pageType} metaData={data.metaData} pageUrl="/">
          <HeroBanner image={data.banner.image.src} imageMobile={data.banner.imageMobile.src}>
            <HeroBannerInner>
              <HeroBannerContent variant={variant} image={data.contentImage}>
                {utils.SafeHtml(data.content)}
              </HeroBannerContent>
            </HeroBannerInner>
          </HeroBanner>
        </Page>
      )}
    </>
  );
};

export default Error404Content;
