import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/Error404';

export const error404Data = () => {
  const data = useStaticQuery(graphql`
    query error404Query {
      dGraphQl {
        nodeQuery(
          filter: {
            conjunction: AND,
            conditions: [
              { field: "type", value: "page" },
              { field: "title", value: "404" }
            ],
          }
        ) {
          entities {
            ... on DrupalGraphQl_NodePage {
              title
              uuid
              entityLabel
              fieldMetaTitle
              fieldMetatags {
                entity {
                  ... on DrupalGraphQl_ParagraphMetatags {
                    fieldDescription
                    fieldKeywords
                    entityBundle
                  }
                }
              }
              fieldBannerTop {
                entity {
                  ...DrupalGraphQlParagraphBannerPage
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.error404Transform(data.dGraphQl);
}


